import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";


export default function FutureFoyle() {
  return (
    <Layout pageTitle="Future Foyle">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Our Future Foyle - USI Projects</title>
        <meta name="description" content="Future Foyle is a transformative, innovative cultural and health intervention focused on the River Foyle in Derry/Londonderry."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

        <section class="text-gray-700 body-font heading-container-foyle">
        <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Our Future Foyle</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Public Health Agency NI</p>
              </div>
          </section>
        </div>
      </section>



      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 pt-6 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-blue-900">Overview</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">Our
                  Future Foyle is a
                  transformative,
                  innovative cultural and health intervention focused on the River Foyle in Derry/Londonderry. The
                  project has been developed over the past three years through extensive community and stakeholder
                  engagement in association with the Public Health Agency, Health Agency NI and Helen Hamlyn Centre for Design.</p>

              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-20">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Mental Health<br/>
                  Communities<br/>Resilience<br/>Public
                  Space<br/>Culture<br/>Regeneration<br/>Economy<br/>Technology</p>
                <p class="text-2xl font-bold uppercase mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Policy Scoping<br/>
                  Market Analysis<br/>Stakeholder Consultation<br/>Public Engagement<br/> Strategic Vision<br/>Design
                  Strategy<br/></p>
                <p class="text-2xl font-bold uppercase">Visit</p>
                <a href="https://www.futurefoyle.org/">
                  <p className=" text-gray-500 leading-relaxed">www.futurefoyle.org
                  </p>
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>




      <div class="pb-12 bg-white">
        <div class="bg-blue-100 py-16">
          <div class="text-center mb-8">
            <p
              class="mt-2 mb-16 text-3xl leading-8 font-bold tracking-tight text-blue-900 sm:text-5xl font-heading">
              This project delivers three interventions
            </p>

            <div class="flex flex-wrap justify-center">

              <div class="mx-8 my-9 md:my-0">
                <img src="/img/projects/foyle/reeds-icon.svg" alt=""/>
              </div>

              <div class="mx-8 my-9 md:my-0">
                <img src="/img/projects/foyle/bubbles-icon.svg" alt=""/>
              </div>

              <div class="mx-8 my-9 md:my-0">
                <img src="/img/projects/foyle/experience.svg" alt=""/>
              </div>

            </div>


          </div>

        </div>
      </div>



      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" class="w-full md:pl-20 md:pr-20 md:pb-20"
        src="https://ik.imagekit.io/usi/project-pages/foyle-timeline_MELkmScGaFVS.svg" alt=""/>




      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 md:px-20 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="grid lg:grid-cols-2 gap-6">
              <div class="w-full ">
                <img src="https://ik.imagekit.io/usi/project-pages/foyle-app_PFn2-9m0Ov.png" alt="foyle app"/>
              </div>
              <div  class="w-full">
                <h2 
                  class="text-2xl md:text-3xl lg:text-4xl pb-6  font-heading ">Foyle
                  Reeds</h2>
                <p 
                  class="text-xl md:text-2xl lg:text-3xl  ">
                  The
                  Foyle
                  Reeds seek to be a cultural installation with the dual purpose of a suicide prevention mechanism on
                  the Foyle Bridge. This installation would be the largest public sculpture in Northern Ireland and
                  aims
                  to develop and inform suicide prevention measures for cities worldwide. Linking the Atlantic Way and
                  Causeway Coastal routes this bridge also links Ireland and Northern Ireland. Utilised and developed
                  to
                  its full potential, the Foyle Bridge has the ability to enhance tourism of the wider area, following
                  the success of the Peace Bridge and the landmark that it has become in the city of Derry. </p>
              </div>
            </div>
          </section>
        </div>
      </section>





      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" class="w-full"
        src="https://ik.imagekit.io/usi/project-pages/foyle-pods_iaGFzrk6Cwpv.png" alt=""/>
      <div class="bg-blue-100">

        <section class="text-gray-700 body-font">
          <div class="container mx-auto flex px-5 md:px-20 md:flex-row flex-col items-center">
            <section>
              <div class="flex flex-wrap">
                <div class="w-full xl:w-3/4 py-12">
                  <h2 data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                    class="text-2xl md:text-3xl lg:text-4xl pb-6  font-heading ">Foyle
                    Bubbles
                  </h2>
                  <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                    class="text-xl md:text-2xl lg:text-3xl ">
                    The
                    Foyle Bubbles; multi-use spaces, designed to be placed on the river banks for creative public
                    engagement with each pod being used by local creative and commercial enterprises within the city.
                    With mental health training and education an essential part of the residency, the occupiers will
                    be a public-facing network of support and response for both the people they are engaging with path
                    and with incidents along the riverside. The potential of the Foyle Bubbles is unlimited for
                    tourism and the success of the engagement. The ability for a pod to answer questions, play songs
                    and monitor the pedestrian flow is an engagement tool able to revolutionise tourism and how the
                    public engages along the river.</p>

                </div>
                <div data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 mt-28">

                </div>
              </div>
            </section>
          </div>
        </section>

      </div>


      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/foyle-peace-bridge_Jipz31Ski.png" alt=""/>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 md:px-20 py-24 md:flex-row flex-col items-center">
          <section>
            <div class="grid lg:grid-cols-2 gap-6">
              <div class="w-full">
                <img data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  src="https://ik.imagekit.io/usi/project-pages/foyle-park_Xjs2qRsg2V.png" alt="foyle app"/>

              </div>
              <div class="w-full ">
                <h2 class="text-2xl md:text-3xl lg:text-4xl font-heading">Foyle
                  Experience
                </h2>
                <p class="text-xl md:text-2xl lg:text-3xl  md:pt-6">
                  The
                  Foyle Experience is a series of creative and innovative installations aiming to benefit “residents
                  and visitors experience of the city, improving connectivity and reducing isolation.” Based around
                  community involvement and engagement, sculptures around the river seek to increase footfall,
                  enhancing city connectivity and increase wayfinding, incorporating social media and digital
                  technology and promoting civic engagement on all levels. </p>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../weavers-cross" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
